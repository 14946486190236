import React from 'react'
import './dpmodel.scss'

const verticalLineXBase = [1, 84, 168, 252, 336, 420, 504, 587]
const lXB = verticalLineXBase

const circlePoints = [42, 56, 44, 46, 20, 37]
const cP = circlePoints.map(v => Math.round(v * 0.7))

class DPModel extends React.Component {
  render () {
    return (
      <div className={`dpmodel
        ${this.props.stateData.testPhase ? 'dpmodel--test-phase' : ''}
        ${this.props.stateData.testDiamond ? 'dpmodel--test-diamond' : ''}
        ${this.props.stateData.preparePhase ? 'dpmodel--prepare-phase' : ''}
        ${this.props.stateData.maintainPhase ? 'dpmodel--maintain-phase' : ''}
        ${this.props.stateData.definePhase ? 'dpmodel--define-phase' : ''}
      `}>
        <svg
          version="1.1" xmlns="http://www.w3.org/2000/svg"
          x="0px" y="0px" viewBox="0 0 588 200"
        >
          <title>modelsai</title>
          <line className="dpmodel__vertical-line" id="vertical-line-1" x1={verticalLineXBase[1]} y1="0" x2={verticalLineXBase[1]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-2" x1={verticalLineXBase[1]} y1="0" x2={verticalLineXBase[1]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-3" x1={verticalLineXBase[2]} y1="0" x2={verticalLineXBase[2]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-4" x1={verticalLineXBase[3]} y1="0" x2={verticalLineXBase[3]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-5" x1={verticalLineXBase[4]} y1="0" x2={verticalLineXBase[4]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-6" x1={verticalLineXBase[4]} y1="0" x2={verticalLineXBase[4]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-7" x1={verticalLineXBase[5]} y1="0" x2={verticalLineXBase[5]} y2="200"/>
          <line className="dpmodel__vertical-line" id="vertical-line-8" x1={verticalLineXBase[5]} y1="0" x2={verticalLineXBase[5]} y2="200"/>

          <line className="dpmodel__phase-line" id="phase-line-7" x1={verticalLineXBase[4]} y1="0" x2={verticalLineXBase[5]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-3" x1={verticalLineXBase[2]} y1="0" x2={verticalLineXBase[3]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-1" x1={verticalLineXBase[1]} y1="0" x2={verticalLineXBase[2]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-2" x1={verticalLineXBase[1]} y1="0" x2={verticalLineXBase[2]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-5" x1={verticalLineXBase[3]} y1="0" x2={verticalLineXBase[4]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-6" x1={verticalLineXBase[4]} y1="0" x2={verticalLineXBase[5]} y2="0"/>
          <line className="dpmodel__phase-line" id="phase-line-4" x1={verticalLineXBase[3]} y1="0" x2={verticalLineXBase[4]} y2="0"/>

          <text x={verticalLineXBase[1] + 8} y="18" id="phase-label-1" className="dpmodel__text">{this.props.stateData.Prepare}</text>
          <text x={verticalLineXBase[1] + 8} y="18" id="phase-label-2" className="dpmodel__text">{this.props.stateData.Understand}</text>
          <text x={verticalLineXBase[2] + 8} y="18" id="phase-label-3" className="dpmodel__text">{this.props.stateData.Define}</text>
          <text x={verticalLineXBase[3] + 8} y="18" id="phase-label-4" className="dpmodel__text">{this.props.stateData.Ideate}</text>
          <text x={verticalLineXBase[3] + 8} y="18" id="phase-label-5" className="dpmodel__text">{this.props.stateData.Test}</text>
          <text x={verticalLineXBase[4] + 8} y="18" id="phase-label-6" className="dpmodel__text">{this.props.stateData.Implement}</text>
          <text x={verticalLineXBase[4] + 8} y="18" id="phase-label-7" className="dpmodel__text">{this.props.stateData.Maintain}</text>

          <g className="diamond1a">
            <line className="dpmodel__line" id="diamond-line-11a" x1={verticalLineXBase[1]} y1="110" x2={verticalLineXBase[2]} y2="30"/>
            <line className="dpmodel__line" id="diamond-line-12a" x1={verticalLineXBase[1]} y1="110" x2={verticalLineXBase[2]} y2="190"/>
          </g>

          <g className="diamond1b">
            <line className="dpmodel__line" id="diamond-line-11b" x1={verticalLineXBase[3]} y1="110" x2={verticalLineXBase[2]} y2="30"/>
            <line className="dpmodel__line" id="diamond-line-12b" x1={verticalLineXBase[3]} y1="110" x2={verticalLineXBase[2]} y2="190"/>
          </g>

          <g className="diamond1circle">
            <path className="dpmodel__line" id="diamond1circle1" d={`M ${lXB[2] - cP[0]},110   C ${lXB[2] - cP[0]},${110 - cP[1]} ${lXB[2] + cP[0]},${110 - cP[1]}  ${lXB[2] + cP[0]},110 C ${lXB[2] + cP[0]},${110 + cP[2]} ${lXB[2] - 8},${110 + cP[3]} ${lXB[2] - cP[4]},${110 + cP[5]}`}/>
            <line className="dpmodel__line" id="diamond1circle2" x1={lXB[2] - cP[4] + 7} y1={110 + cP[5] - 1} x2={lXB[2] - cP[4]} y2={110 + cP[5]}/>
            <line className="dpmodel__line" id="diamond1circle3" x1={lXB[2] - cP[4] + 2} y1={110 + cP[5] + 6} x2={lXB[2] - cP[4]} y2={110 + cP[5]}/>
          </g>

          <g className="diamond2a">
            <line className="dpmodel__line" id="diamond-line-21a" x1={verticalLineXBase[3]} y1="110" x2={verticalLineXBase[4]} y2="30"/>
            <line className="dpmodel__line" id="diamond-line-22a" x1={verticalLineXBase[3]} y1="110" x2={verticalLineXBase[4]} y2="190"/>
          </g>

          <g className="diamond2b">
            <line className="dpmodel__line" id="diamond-line-21b" x1={verticalLineXBase[5]} y1="110" x2={verticalLineXBase[4]} y2="30"/>
            <line className="dpmodel__line" id="diamond-line-22b" x1={verticalLineXBase[5]} y1="110" x2={verticalLineXBase[4]} y2="190"/>
          </g>

          <g className="diamond2circle">
            <path className="dpmodel__line" d={`M ${lXB[4] - cP[0]},110   C ${lXB[4] - cP[0]},${110 - cP[1]} ${lXB[4] + cP[0]},${110 - cP[1]}  ${lXB[4] + cP[0]},110 C ${lXB[4] + cP[0]},${110 + cP[2]} ${lXB[4] - 8},${110 + cP[3]} ${lXB[4] - cP[4]},${110 + cP[5]}`}/>
            <line className="dpmodel__line" id="diamond-line-impl2" x1={lXB[4] - cP[4] + 7} y1={110 + cP[5] - 1} x2={lXB[4] - cP[4]} y2={110 + cP[5]}/>
            <line className="dpmodel__line" id="diamond-line-impl3" x1={lXB[4] - cP[4] + 2} y1={110 + cP[5] + 6} x2={lXB[4] - cP[4]} y2={110 + cP[5]}/>
          </g>

          <g id="diamond-line-impl">
            <line className="dpmodel__line" id="diamond-line-impl1" x1={verticalLineXBase[5]} y1="110" x2={verticalLineXBase[6]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-impl2" x1={verticalLineXBase[6] - 5} y1="105" x2={verticalLineXBase[6]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-impl3" x1={verticalLineXBase[6] - 5} y1="115" x2={verticalLineXBase[6]} y2="110"/>
          </g>

          <g id="diamond-line-maintain">
            <line className="dpmodel__line" id="diamond-line-maintain1" x1={verticalLineXBase[5]} y1="110" x2={verticalLineXBase[6]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-maintain2" x1={verticalLineXBase[6] - 5} y1="105" x2={verticalLineXBase[6]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-maintain3" x1={verticalLineXBase[6] - 5} y1="115" x2={verticalLineXBase[6]} y2="110"/>
          </g>

          <g id="diamond-line-prepare">
            <line className="dpmodel__line" id="diamond-line-prepare1" x1={verticalLineXBase[1]} y1="110" x2={verticalLineXBase[2]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-prepare2" x1={verticalLineXBase[2] - 5} y1="105" x2={verticalLineXBase[2]} y2="110"/>
            <line className="dpmodel__line" id="diamond-line-prepare3" x1={verticalLineXBase[2] - 5} y1="115" x2={verticalLineXBase[2]} y2="110"/>
          </g>
        </svg>
      </div>
    )
  }
}

export default DPModel
