import React from 'react'
import './questions.scss'

class QuestionsCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: this.props.initialShow || false,
      disable: false,
      showInfo: false,
      buttonActive: this.props.buttonActive
    }
  }

  updateState (states) {
    this.setState(states)
  }

  render () {
    return (
      <div className={`questions__card questions__card--broad ${this.state.disable ? 'disable' : ''} ${this.state.show ? 'show' : ''} ${this.state.showInfo ? 'show-info' : ''}`}>

        <div className="questions__card-list">
          <div>
            <select name="prepare" id="prepare-select" onChange={this.props.renameEvent} value={this.props.stateData.Prepare}>
              <option value="Prepare">Prepare</option>
              <option value="Kick-start">Kick-start</option>
            </select>
            <p>This phase prepares the process and project so that there is direction and resources to start.</p>
          </div>
          <div>
            <select name="understand" id="understand-select" onChange={this.props.renameEvent}>
              <option value="Discover" defaultValue>Discover</option>
              <option value="Understand">Understand</option>
              <option value="Analysing">Analyse</option>
              <option value="Exploration">Explore</option>
              <option value="Research">Research</option>
            </select>
            <p>The designer explores the problem space for the true problem and creates a holistic view.</p>
          </div>
          <div>
            <select name="define" id="define-select" onChange={this.props.renameEvent}>
              <option value="Define" defaultValue>Define</option>
              <option value="Framing">Frame</option>
            </select>
            <p>Findings from the analysis are summarised and concluded in the creative brief.</p>
          </div>
          <div>
            <select name="ideate" id="ideate-select" onChange={this.props.renameEvent}>
              <option value="Develop" defaultValue>Develop</option>
              <option value="Ideate">Ideate</option>
              <option value="Generating">Generate</option>
              <option value="Creation">Create</option>
            </select>
            <p>Concepts are developed with an iterative process for creating and refining solutions.</p>
          </div>
          <div>
            <select name="test" id="test-select" onChange={this.props.renameEvent}>
              <option value="Test" defaultValue>Test</option>
              <option value="Prototype">Prototype</option>
              <option value="Reflection">Reflect</option>
              <option value="Evaluate">Evaluate</option>
            </select>
            <p>Testing is essential to evaluate the trials with prototyping, user tests and reflection.</p>
          </div>
          <div>
            <select name="implement" id="implement-select" onChange={this.props.renameEvent}>
              <option value="Deliver" defaultValue>Deliver</option>
              <option value="Implement">Implement</option>
              <option value="Launch">Launch</option>
            </select>
            <p>The service concept delivery is prepared and realised.</p>
          </div>
          <div>
            <select name="maintain" id="maintain-select" onChange={this.props.renameEvent}>
              <option value="Maintain" defaultValue>Maintain</option>
              <option value="Optiminze">Optiminze</option>
            </select>
            <p>This phase continues and improves the service(s) after implementing the service concept.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionsCard
