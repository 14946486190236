import React from 'react'
import './questions.scss'

class QuestionsCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: this.props.initialShow || false,
      disable: false,
      showInfo: false,
      buttonActive: this.props.buttonActive,
      // buttonOriginalActive: this.props.buttonActive,
      buttonPrevSelected: 0,
      buttonSelected: 0
    }
    // this.buttonOriginalActive =
    this.toggleInfo = this.toggleInfo.bind(this)
  }

  updateState (states) {
    states.buttonPrevSelected = this.state.buttonSelected
    this.setState(states)
  }

  toggleInfo (e) {
    e.preventDefault()
    this.setState({ showInfo: !this.state.showInfo })
  }

  render () {
    const buttons = (this.props.buttons || []).map((item) =>
      <a key={`questions-button-${this.props.id}-${item.id}`}
        href="#answer" data-qa={item.value} onClick={this.props.triggerEvent}
        className={`questions__button ${this.state.buttonActive === item.id ? 'active' : ''} ${this.state.buttonSelected === item.id ? 'selected' : ''}`}
      >{item.name}</a>
    )

    const updateElement = this.props.changeTexts ? (
      <p className="questions__card-update">
        {(this.state.buttonSelected > 0 && (this.props.buttonActive !== this.state.buttonSelected)) ? this.props.changeTexts[0]
          : (this.state.buttonPrevSelected > 0 ? this.props.changeTexts[2] : this.props.changeTexts[1])}
      </p>
    ) : false

    const disableElement = (
      <p className="questions__card-disable">
        This question became irrelevant because you answered that there should be no separate {this.props.testPhaseName} phase.
      </p>
    )

    return (
      <div className={`questions__card
        ${this.state.disable ? 'disable' : ''}
        ${this.state.show ? 'show' : ''}
        ${this.state.showInfo ? 'show-info' : ''}
        ${this.state.buttonSelected ? 'button-selected' : ''}
      `}>
        <div className="questions__card-text">
          <p>{this.props.text}</p>
          <p className="questions__card-more">
            {this.props.children}
          </p>
          {updateElement} {disableElement}
        </div>
        <div className="questions__card-buttons">
          {buttons}
          <a href="#answer" onClick={this.toggleInfo} className={`questions__button questions__button--secondary ${this.state.showInfo ? 'selected' : ''}`}>
            {this.props.infoButtonText || 'Why?'}
          </a>
        </div>
      </div>
    )
  }
}

export default QuestionsCard
