import React from 'react'
import { Sticky } from 'react-sticky'

import Layout from '../../components/Layout/layout-single'
import SEO from '../../components/SEO/seo'
import DPModel from '../../components/Designprocess/dpmodel'
import QuestionsCard from '../../components/Designprocess/questionscard'
import QuestionsCardPhases from '../../components/Designprocess/questionscard-phases'
import Header from '../../components/Header/header'

class DesignprocessPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      Prepare: 'Prepare',
      Understand: 'Discover',
      Define: 'Define',
      Ideate: 'Develop',
      Test: 'Test',
      Implement: 'Deliver',
      Maintain: 'Maintain',
      testPhase: false,
      testDiamond: false,
      preparePhase: false,
      maintainPhase: false,
      definePhase: true,
      phasesRenamed: false,
      userIsDone: false
    }
    this.startEvent = this.startEvent.bind(this)
    this.doEventDefault = this.doEventDefault.bind(this)
    this.setTest = this.setTest.bind(this)
    this.setImplementation = this.setImplementation.bind(this)
    this.setPrepare = this.setPrepare.bind(this)
    this.setMaintain = this.setMaintain.bind(this)
    this.setDefine = this.setDefine.bind(this)
    this.showRename = this.showRename.bind(this)
    this.renameEvent = this.renameEvent.bind(this)
    this.DPModelRef = React.createRef()
    this.StartElementRef = React.createRef()
    this.TestPhaseElementRef = React.createRef()
    this.ImplementationPhaseElementRef = React.createRef()
    this.PreparePhaseElementRef = React.createRef()
    this.MaintainPhaseElementRef = React.createRef()
    this.DefinePhaseElementRef = React.createRef()
    this.RenamePhaseElementRef = React.createRef()
    this.RenameQuestionElementRef = React.createRef()
  }

  startEvent (e) {
    e.preventDefault()
    this.TestPhaseElementRef.current.updateState({ show: true })
    this.StartElementRef.current.updateState({ buttonSelected: '1' })
  }

  doEventDefault (e, doIfWorks) {
    // not for start
    e.preventDefault()
    if (e.target && e.target.attributes && e.target.attributes['data-qa']) {
      const newState = e.target.attributes['data-qa'].nodeValue === 'true'
      doIfWorks(newState)
    }
  }

  setTest (e) {
    this.doEventDefault(e, newState => {
      this.setState({ testPhase: newState })
      this.TestPhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      if (newState) {
        this.ImplementationPhaseElementRef.current.updateState({ show: true, disable: false })
      } else {
        this.ImplementationPhaseElementRef.current.updateState({ disable: true })
        this.PreparePhaseElementRef.current.updateState({ show: true })
      }
    })
  }

  setImplementation (e) {
    this.doEventDefault(e, newState => {
      this.setState({ testDiamond: newState })
      this.ImplementationPhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      this.PreparePhaseElementRef.current.updateState({ show: true })
    })
  }

  setPrepare (e) {
    this.doEventDefault(e, newState => {
      this.setState({ preparePhase: newState })
      this.PreparePhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      this.MaintainPhaseElementRef.current.updateState({ show: true })
    })
  }

  setMaintain (e) {
    this.doEventDefault(e, newState => {
      this.setState({ maintainPhase: newState })
      this.MaintainPhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      this.DefinePhaseElementRef.current.updateState({ show: true })
    })
  }

  setDefine (e) {
    this.doEventDefault(e, newState => {
      this.setState({ definePhase: newState })
      this.DefinePhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      this.RenamePhaseElementRef.current.updateState({ show: true })
    })
  }

  showRename (e) {
    this.doEventDefault(e, newState => {
      this.setState({ userIsDone: !newState })
      this.RenamePhaseElementRef.current.updateState({ buttonSelected: (newState ? '1' : '2'), buttonActive: '0' })
      this.RenameQuestionElementRef.current.updateState({ show: newState })
    })
  }

  renameEvent (e) {
    e.preventDefault()
    if (e.target.name) {
      let label = e.target.name
      label = label.charAt(0).toUpperCase() + label.substring(1)
      const value = e.target.value
      this.setState({ [label]: value, phasesRenamed: true })
    }
  }

  render () {
    const data = this.props.data

    const StartElement = (
      <QuestionsCard ref={this.StartElementRef} id="2"
        text="Let's start with the Double Diamond of the UK Design Council from 2005."
        triggerEvent={this.startEvent} buttonActive="0" infoButtonText="The what?"
        buttons={[{ value: 'true', name: "Let's go!", id: '1' }]}
        initialShow={true}
      >
        The design process is a non-linear iterative process of diverging and converging. Most service designers base their process on the double diamond model (<a href="http://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE07104191#">Yu, 2017</a>). The double diamond stands for two sets diverging and converging phases: Discover/Define and Develop/Deliver (<a href="https://www.designcouncil.org.uk/news-opinion/double-diamond-universally-accepted-depiction-design-process">Ball, n.d.</a>).
      </QuestionsCard>
    )

    const TestPhaseElement = (
      <QuestionsCard ref={this.TestPhaseElementRef} id="1"
        text={`Should ${this.state.Test} be a separate phase?`}
        triggerEvent={this.setTest} buttonActive="2"
        buttons={[{ value: 'true', name: 'Yup', id: '1' }, { value: 'false', name: 'Nope', id: '2' }]}
        changeTexts={[`The ${this.state.Test} phase is added to the ${this.state.Ideate} phase.`, 'The model remains the same.', `The ${this.state.Test} phase is included in the ${this.state.Ideate} phase.`]}
      >
        Some models include the {this.state.Test} in {this.state.Ideate} (<a href="http://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE07104191#">Yu, 2017</a>), but in other cases, {this.state.Test} is treated as a separate phase (<a href="http://thisisservicedesignthinking.com/">Stickdorn et al., 2011</a>). You can choose to make testing explicit to demonstrate its importance or
        the diverging/converging contrast with ideation. When you have a {this.state.Test} phase, you'll be able to choose how this diamond is shaped.
      </QuestionsCard>
    )

    const ImplementationPhaseElement = (
      <QuestionsCard ref={this.ImplementationPhaseElementRef} id="2"
        text={`Is ${this.state.Test} or ${this.state.Implement} the second part of the solution space diamond?`}
        triggerEvent={this.setImplementation} buttonActive="2"
        buttons={[{ value: 'true', name: `${this.state.Test}`, id: '1' }, { value: 'false', name: `${this.state.Implement}`, id: '2' }]}
        changeTexts={[`The ${this.state.Test} phase is now the counterpart of the ${this.state.Ideate} phase.`, 'The diamond remains the same.', `The ${this.state.Implement} phase is now the counterpart of the ${this.state.Ideate} phase.`]}
        testPhaseName={this.state.Test}
      >
      The diamonds represent iterative matches between two circular diverging and converging activities, such as the first diamond ({this.state.Understand}/{this.state.Define}) is for the problem space. {this.state.Ideate} can have a similar match in the solution space with {this.state.Test} or {this.state.Implement}. You could keep the original diamond or move {this.state.Implement} out the diamond. What is the converging end for you; the tested solution just before delivery/implementation or the delivered solution itself?
      </QuestionsCard>
    )

    const PreparePhaseElement = (
      <QuestionsCard ref={this.PreparePhaseElementRef} id="3"
        text={`Should there be a ${this.state.Prepare} phase before starting the project?`} // to include activities such as pitching?`}
        triggerEvent={this.setPrepare} buttonActive="2"
        buttons={[{ value: 'true', name: 'Yup', id: '1' }, { value: 'false', name: 'Nope', id: '2' }]}
        changeTexts={[`The ${this.state.Prepare} phase is added before the ${this.state.Understand} phase.`, 'The model remains the same.', `The ${this.state.Prepare} phase is removed.`]}
      >
        {this.state.Prepare} is the phase that involves the activities of the service designer before kickstarting the project, such as preparing the service design process and perhaps explaining what service design is to the client (<a href="http://thisisservicedesignthinking.com/">Stickdorn et al., 2011</a>). Other activities could include preparing pitches or kick-off.
      </QuestionsCard>
    )

    const MaintainPhaseElement = (
      <QuestionsCard ref={this.MaintainPhaseElementRef} id="4"
        text={`Should there be a ${this.state.Maintain} phase after the delivery?`} // to include activities such as optimizing?`}
        triggerEvent={this.setMaintain} buttonActive="2"
        buttons={[{ value: 'true', name: 'Yup', id: '1' }, { value: 'false', name: 'Nope', id: '2' }]}
        changeTexts={[`The ${this.state.Maintain} phase is added after the ${this.state.Implement} phase.`, 'The model remains the same.', `The ${this.state.Maintain} phase is removed.`]}
      >
      A possible final phase is {this.state.Maintain}, where designers continue in assisting the services without starting a new project. This phase continues and improves the service(s) after implementing the service concept. <a href="http://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE07104191#">Yu (2017)</a> stated that the service implementation activities are unknown or limited in these service design processes models. These activities would fit in the {this.state.Implement} and {this.state.Maintain} phases.
      </QuestionsCard>
    )

    const DefinePhaseElement = (
      <QuestionsCard ref={this.DefinePhaseElementRef} id="5"
        text={`Should ${this.state.Define} be a separate phase?`}
        triggerEvent={this.setDefine} buttonActive="1"
        buttons={[{ value: 'true', name: 'Yup', id: '1' }, { value: 'false', name: 'Nope', id: '2' }]}
        changeTexts={[`The ${this.state.Define} phase is removed from the model.`, 'The model remains the same.', `The ${this.state.Define} phase is added after the ${this.state.Understand} phase.`]}
      >
        Some models include the {this.state.Define} in {this.state.Understand} (<a href="http://thisisservicedesignthinking.com/">Stickdorn et al., 2011</a>). In other cases, {this.state.Define} is treated as a separate phase (<a href="http://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE07104191#">Yu, 2017</a>). Typically, the findings from the analysis are summarised and concluded in this phase.
      </QuestionsCard>
    )

    const RenamePhaseElement = (
      <QuestionsCard ref={this.RenamePhaseElementRef} id="5"
        text={'Do you want to select new names for the phases?'}
        triggerEvent={this.showRename} buttonActive="2"
        buttons={[{ value: 'true', name: 'Yup', id: '1' }, { value: 'false', name: (this.state.phasesRenamed ? "I'm done" : 'Nope'), id: '2' }]}
        changeTexts={[(this.state.phasesRenamed ? 'The phase names are updated.' : 'You can find the phases below.'), 'The model remains the same.', (this.state.phasesRenamed ? 'The phase names are updated.' : 'The model remains the same.')]}
      >
        The same phase can have various names over different models. For example, the {this.state.Understand} phase is called 'Discover' in the original Double Diamond and 'Explore' by Stickdorn et al. Select below a name for each phase from a list.
      </QuestionsCard>
    )

    return (

      <Layout>
        <>
          <SEO title="Design Process Modeler" location={this.props.location} />
          <Header
            location={this.props.location}
            alt="Exploring the design process model"
            title="Exploring the design process model"
          />
        </>
        <>
          <h1 className="dpmodel-title">Design Process Modeler</h1>

          <Sticky topOffset={75 + 50}>
            {({ style, isSticky }) => (
              <header style={style} className={`dpmodel-header ${isSticky ? 'dpmodel-header--sticky' : ''}`}>
                <DPModel ref={this.DPModelRef} stateData={this.state}/>
              </header>
            )}
          </Sticky>

          <div className="dpmodel-welcome">
            <p>Welcome to the Modeler, which evolves a model that represents your design process. It adapts to your answers to a series of the questions below. Please feel free to explore and test the model and its phases. Here is a little warning: it is work in progress. You can expect textual and interaction bugs. Help me by providing feedback: <a href="https://www.linkedin.com/in/youettakunneman/">Send me a message</a>.</p>
          </div>

          <div className="questions__wrapper">
            <div className="questions">
              {StartElement}
              {TestPhaseElement}
              {ImplementationPhaseElement}
              {PreparePhaseElement}
              {MaintainPhaseElement}
              {DefinePhaseElement}
              {RenamePhaseElement}
              <QuestionsCardPhases ref={this.RenameQuestionElementRef} stateData={this.state} renameEvent={this.renameEvent}/>
              <div className={`questions__final ${this.state.userIsDone ? 'show' : ''}`}>
                <p>That's it! There are no more questions, but you can still adjust your answers to see the effects.</p>
              </div>
            </div>
          </div>
        </>
      </Layout>

    )
  }
}

// <p>
//   (<a href="https://www.designcouncil.org.uk/news-opinion/double-diamond-universally-accepted-depiction-design-process">Ball, n.d.</a>)
//   (<a href="http://www.dbpia.co.kr/Journal/articleDetail?nodeId=NODE07104191#">Yu, 2017</a>)
//   (<a href="http://thisisservicedesignthinking.com/">Stickdorn et al., 2011</a>)
// </p>

export default DesignprocessPage
